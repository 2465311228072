<template>
  <div id="Home">
    <div class="bearIcon"> 🍻 </div>
    <div class="versionText"> v.0.1.0 </div>
  </div>
</template>

<script>
  export default {
    name: 'Home',
  };
</script>

<style lang="scss" scoped>
  #Home {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    .bearIcon {
      font-size: 15rem;
      text-align: center;
    }
    .versionText {
      font-size: 12px;
      letter-spacing: 1.5px;
    }
  }
</style>
